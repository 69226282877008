@tailwind base;
@tailwind components;
@tailwind utilities;


.text-display {
  margin-bottom: 20px;
  border-radius: 5px;
}


.results {
  font-size: 20px;
  margin-top: 20px;
}

.text-display {
  margin: 0 auto;
  position: relative;
  transition: filter 0.3s ease, opacity 0.3s ease;
}
.text-display.finished {
  filter: blur(8px);
  opacity: 0.7;
}
.text-display span {
  position: relative;
  margin-bottom: 10px;
}
.text-display .space {
  width: 50px;
}
.text-display .cursor {
  position: absolute;
  left: -2px;
  transition: left 0.1s ease-out
}
.text-display .cursor.not-active::before {
  animation: blink 0.5s steps(2) infinite;
}
.text-display .cursor::before {
  content: '|';
  left: -1px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ad-banner-728x90 {
  width: 728px;
  height: 90px;
}